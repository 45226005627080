.main
  // margin-left: 170px
  // margin-right: 40px
  // padding: 20px 30px
  margin-top: 20px
  padding-bottom: 20px
  margin-bottom: 40px
  border-radius: 10px
  background-repeat: no-repeat

.title
  margin-left: 170px
  padding-top: 20px
  font-size: 45px
  font-weight: 700
  max-width: 15ch
  line-height: 1
  & > span
      color: #ff7637

.list
  display: flex
  gap: 20px
  overflow-x: auto
  box-sizing: border-box
  width: 98vw

  &::-webkit-scrollbar
    display: none

  > div:first-child
    margin-left: 170px