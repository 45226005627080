@use '../main/colors'

.main
  width: 100%
  height: 70px
  box-sizing: border-box
  padding: 20px
  border-radius: 5px
  cursor: pointer
  margin-bottom: 20px
  display: flex
  flex-direction: row
  justify-content: space-between

.label
  color: #F1F1F1
  font-size: 20px

.icon
  width: 28px
  height: 28px

.main[keynavitemfocus]
  background-color: #FFF

  .label
    color: colors.$bg_1

  .icon
    svg path
      fill: colors.$bg_1

.main:active
  transform: translateY(1px)
