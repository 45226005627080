@use "../main/colors"

.main
  background-color: transparent
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100vh
  box-sizing: border-box
  overflow-y: auto

.shows_title
  margin: 50px 0 0 176px

  span
    color: colors.$bg_2
