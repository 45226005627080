@use '../main/colors' as colors

.main
  background: transparent
  display: flex
  flex-direction: column
  padding-left: 120px
  min-height: 100vh

.channel_ctn
  padding: 25px 32px
  background-color: #222223
  height: 100%
  display: flex

.tab_ctn
  padding-right: 30px
  border-right: 2px solid #FF7637

.tab_list
  display: flex
  flex-direction: column
  gap: 16px
  margin: 0
  padding: 0
  width: 150px

.tab
  color: white
  font-size: 16px
  font-weight: 700
  max-width: 20ch
  background-color: colors.$bg_5
  padding: 20px 31px
  position: relative
  border-radius: 4px
  overflow: hidden
  box-sizing: border-box
  margin: 0
  list-style: none
  transition: background-color 0.3s, color 0.3s // opcional, para transiciones suaves
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 10px
    height: 100%
    background-color: #999999
    transition: background-color 0.3s // opcional, para transiciones suaves

  &.active::before
    background-color: colors.$bg_2

  &[keynavitemfocus]
    background-color: colors.$bg_13

    &::before
      background-color: #FFF

    &.active::before
      background-color: colors.$bg_2

.chapter_ctn
  padding-left: 30px
  display: flex
  flex-wrap: wrap
  gap: 15px
  overflow-y: auto
  height: 270px
  position: relative
  width: 100%

.fullscreen_main
  position: fixed
  top: 0
  right: 0
  width: 100%
  height: 100%
  z-index: 100
