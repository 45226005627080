@use '../main/colors'

.wrap
  display: flex
  flex-direction: row
  cursor: pointer

  &:active
    transform: translateY(1px)

.main
  width: 270px
  height: 151px
  min-width: 270px
  min-height: 151px
  border-radius: 5px
  position: relative
  margin: 0 10px
  border: 5px solid transparent
  transition: all .2s

.picture
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #000
  background-size: contain
  background-position: center
  background-repeat: no-repeat
  overflow: hidden
  border-radius: 5px
  box-shadow: 0 0 20px #000

.secundary
  padding-left: 10px

.title
  font-size: 18px
  font-weight: bold
  margin: 10px 0 0
  color: #999

.description
  font-size: 13px
  margin: 0
  color: #999

[keynavitemfocus]
  .title,
  .description
    color: #FFF

  .main
    border-color: #FFF
    transform: scale(1.05)

  .picture
    box-shadow: unset
