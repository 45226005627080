.main
  background-color: transparent
  position: absolute
  top: 0
  left: 0
  width: 100%
  min-height: 100vh
  padding-top: 50vh
  box-sizing: border-box
  
.banner
  position: fixed
  top: 0
  left: 0
  z-index: 0
  width: 100%
  height: 50vh
  max-height: 600px

.body
  overflow-x: hidden
  overflow-y: auto
  position: absolute
  top: 20vh
  left: 0
  padding-top: 40px
  height: 80vh
  box-sizing: border-box
  -webkit-mask-image: linear-gradient(0deg, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)

  &::-webkit-scrollbar
    display: none

.grid
  display: flex
  padding-top: 20px
  margin-left: 150px
  flex-direction: row
  flex-wrap: wrap

  > div
    margin-bottom: 20px

@media screen and (min-height: 1200px)
  .main
    padding-top: 600px
    
