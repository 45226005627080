.main
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  box-sizing: border-box
  padding: 30px 100px

.inner
  display: block
  width: 100%
  max-width: 800px
  margin: 0 auto

.title
  color: #FFF
  font-size: 60px
  font-weight: bold
  margin: 50px 0 50px
  text-align: center

.version
  width: 95%
  margin: 0 auto
  padding: 20px 0
  border-top: 2px solid #FFF
  font-size: 18px
