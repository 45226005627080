@use '../main/colors'

.main
  display: inline-block
  background-color: colors.$bg_5
  padding: 7px 10px
  border: 1px solid colors.$bg_1
  border-radius: 30px
  min-width: 45px
  text-align: center
  font-size: 10px
  line-height: 10px
  vertical-align: middle
  color: #FFF
  font-weight: bold

  &[keynavitemfocus]
    background-color: colors.$bg_2
    border-color: #FFF
    color: #FFF

.active
  background-color: #FFF
  color: colors.$bg_2
  box-shadow: 0 4px 8px rgba(0,0,0, 0.8)
  border-color: #FFF

@media screen and (min-width: 1281px)
  .main
    font-size: 15px
    padding: 10px 20px