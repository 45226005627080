@use '../main/colors'
@use '../main/typo'

.main
  position: relative

.input_container
  display: flex
  align-items: center
  background-color: #373342
  border: 2px solid transparent
  border-radius: 5px


.input
  background-color: #373342
  border: none
  padding-right: 10px
  width: 250px
  height: 40px
  color: #FFF
  font-size: 15px
  font-family: typo.$default
  box-sizing: border-box
  // text-indent: 40px

  &::placeholder
    color: #FFF

[keynavitemfocus] 
  .input_container
    border-color: #FFF
  .input
    outline: none

.btn 
  width: 50px
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  // position: absolute
  top: 0
  left: 0
  z-index: 2

  &:active
    transform: translateY(1px)

  svg
    width: 30px
    height: 20px
