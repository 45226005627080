@use '../main/colors'

.main
  position: relative
  width: 100%
  height: 50vh
  background-color: #000
  overflow: hidden

.shadow
  position: absolute
  top: 0
  left: 0
  z-index: 2
  background: linear-gradient(180deg, rgba(colors.$bg_1,0) 30%, rgba(colors.$bg_1,1) 100%), linear-gradient(90deg, rgba(colors.$bg_1,1) 0%, rgba(colors.$bg_1,1) 10%, rgba(colors.$bg_1,0) 40%)
  width: 100%
  height: 100%

.picture
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-size: cover
  background-repeat: no-repeat
  background-position: center
  filter: blur(1px)

