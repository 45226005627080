.wrap
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  overflow: hidden
  z-index: 10
  background-color: #000

.player
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  > iframe
    width: 100%
    height: 100%

.btnBack
  position: absolute
  top: 0
  right: 0
  width: 50px
  height: 50px
  z-index: 11
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

  &:active
    transform: translateY(1px)

[keynavitemfocus] .btnBack
  background-color: #FFF
  // background-color: rgba(0, 0, 0, 0.1)
  border-radius: 5px

  path
    fill: #000

.title
  position: absolute
  top: 0
  left: 0
  padding: 20px
  color: #FFF
  font-weight: bold
  font-size: 30px
  z-index: 11

.title,
.btnBack,
.controls
  opacity: 0
  transition: opacity .1s 

.overlay
  background-color: transparent
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 100

  &:hover
    .title,
    .btnBack,
    .controls
      opacity: 1

.controls
  background-color: rgba(#000, 0.5)
  position: absolute
  bottom: 0
  left: 0
  width: 100%

.controls_inner
  width: 80%
  margin: 0 auto

.timeline
  width: 100%
  height: 7px
  background-color: #78C8DA
  border-radius: 14px 
  position: relative
  cursor: pointer

.timeline_played
  position: absolute
  top: 0
  left: 0
  height: 7px
  width: 0%
  background-color: #2E7787
  border-radius: 14px 

.buttons
  padding: 15px 0
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center

.controls_player
  display: flex
  align-items: center
  justify-content: center
  width: 90%

  > div
    width: 50px
    height: 50px
    display: flex
    justify-content: center
    align-items: center
    margin: 0 5px

.controls_btn,
.btn_prev,
.btn_play,
.btn_next,
.btn_fullscreen
  opacity: 0.5
  cursor: pointer

  &:active
    transform: translateY(1px)

  &[keynavitemfocus]
    opacity: 1

.btn_fullscreen
  position: absolute
  top: 50%
  right: 50px
  transform: translateY(-50%)

  &:active
    transform: translateY(-49%)

.time_played,
.time_total
  font-size: 18px
  font-weight: bold

.showControls
  .title,
  .btnBack,
  .controls
    opacity: 1 !important

.nextChapterContent
  position: absolute
  bottom: 130px
  right: 20px
  z-index: 100

.inline
  .overlay
    z-index: 10

.tooltip
  position: absolute
  top: -40px
  right: 0
  background-color: #000
  color: #FFF
  font-size: 18px
  font-weight: bold
  transform: translateX(50%)
  padding: 3px 10px
  border-radius: 5px

  &:after
    content: ''
    position: absolute
    top: 100%
    left: 50%
    transform: translateX(-50%)
    width: 0
    height: 0
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-top: 10px solid #000
