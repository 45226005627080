.main
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 0 100px
  box-sizing: border-box
  display: flex
  justify-content: center
  align-items: center

.title
  color: #FFF
  font-size: 60px
  font-weight: bold
  margin: 50px 0 50px
  text-align: center

.content
  font-size: 20px
  margin-bottom: 30px

.message
  font-size: 20px
  margin-bottom: 30px
  display: flex
  flex-direction: column
  text-align: center

.messageButton
  width: 120px
  margin: 0 auto

.label
  padding: 10px 0

.footer
  margin: 0 auto
  padding: 20px 0
  border-top: 2px solid #FFF
  font-size: 18px
