.loading_tiny,
.main
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%

.loader_content
  width: 100%
  height: 100%
  max-width: 50px
  max-height: 50px
  position: relative

.lds_ring 
  display: inline-block
  position: relative
  width: 100%
  height: 100%

.lds_ring div 
  box-sizing: border-box
  display: block
  position: absolute
  width: 100%
  height: 100%
  max-width: 64px
  max-height: 64px
  margin: 0px
  border: 8px solid #f2eeee
  border-radius: 50%
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
  border-color: #f2eeee transparent transparent transparent

.lds_ring div:nth-child(1) 
  animation-delay: -0.45s

.lds_ring div:nth-child(2) 
  animation-delay: -0.3s

.lds_ring div:nth-child(3) 
  animation-delay: -0.15s

@keyframes lds_ring 
  0% 
    transform: rotate(0deg)
  
  100% 
    transform: rotate(360deg)

.theme_dark
  .lds_ring div
    border-color: #FF5000 transparent transparent transparent

.tiny
  width: 40px
  height: 40px
  position: static

  .lds_ring div
    border-width: 6px

.verytiny
  width: 20px
  height: 20px
  position: static

  .lds_ring div
    border-width: 4px
