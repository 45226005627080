.main
  position: absolute
  top: 0
  left: 0
  padding: 0 100px
  width: 100%
  height: 100%
  box-sizing: border-box
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box

.info
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.logo
  svg
    display: block
    margin: 0 auto

.buttons
  display: flex
  justify-content: center
  align-items: center
  margin-top: 20px
  gap: 10px
