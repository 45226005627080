.main
    padding-left: 170px
    padding-right: 40px

.MostRecentEpisodes
    display: flex
    flex-wrap: wrap
    padding: 0
    gap: 15px

.MostRecentEpisodes_label
    font-size: 18px
    font-weight: bold
    color: #FFF
    margin-top: 20px
