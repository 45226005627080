.main
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  box-sizing: border-box
  padding: 0 100px
  display: flex
  justify-content: center
  align-items: center

.title
  color: #FFF
  font-size: 60px
  font-weight: bold
  margin: 0 0 40px
  text-align: center
  
.description
  text-align: center
  font-size: 30px
