@use '../main/colors'

.content
  display: inherit

.inner
  padding: 7px 0
  display: flex
  flex-direction: row
  border-bottom: 1px solid colors.$bg_11

.side
  font-size: 11px
  font-weight: bold
  padding: 0
  width: 58px
  text-align: center

.main
  padding: 0
  width: calc(100% - 135px)

.title
  font-size: 11px
  font-weight: bold

.description
  font-size: 10px
  margin: 7px 0 0
  display: none

.btn
  margin-top: 10px
  display: none

.content[state='active']
  .inner
    background: #FFF
    color: colors.$bg_12
    border: 3px solid #FFF

    .description
      display: block
      color: colors.$bg_1
    
    .btn
      display: inline-block

  &[keynavitemfocus]
    .inner
      border-color: colors.$bg_2 !important

@media screen and (min-width: 1281px)
  .side
    font-size: 20px
    width: 100px

  .title
    font-size: 20px
  
  .description
    font-size: 15px