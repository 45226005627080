@use '../main/colors'
.PopUp
    position: fixed
    right: 10px
    padding:20px
    border-radius:5px
    font-weight: bold
    min-width:200px
    text-align: center
    bottom: 10px
    background-color: colors.$bg_2
    transition: transform 0.3s ease-in
    transform: translateY(calc(100% + 15px))
    z-index:20

.isActive
    transform: translateY(0)
    