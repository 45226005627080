@use '../main/colors' as colors

.chapter
    display: flex
    border-radius: 4px
    overflow: hidden
    background-color: colors.$bg_5
    animation-name: fadein
    animation-duration: 0.5s
    animation-timing-function: ease-out
    width: 30%
    min-width: 273px

    &.live
        border: 1px solid colors.$bg_2
        color: #fff

.chapter_side
    display: flex
    width: 30%

.capter_live_ctn
    display: flex
    flex-direction: column
    justify-content: center
    padding-left: 28px
    height: 100%
    gap: 15px
.capter_live_ctn > p
    margin: 0
    font-weight: 700
    max-width: 8ch
    font-size: 16px
.chapter_image
    background-color: colors.$bg_5
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
    background-blend-mode: luminosity
    width: 100%
    height: 100%
.chapter_info
    padding: 30px 20px
.chapter_title
    font-weight: 700
    font-size: 15px
    margin: 0
.chapter_number
    font-size: 14px
    margin: 0
.chapter_date
    font-size: 10px
    margin: 0
    margin-top: 5px
    font-weight: 700
.chapter_progressbar_ctn
    margin-top: 10px
    border-radius: 4px
    height: 4px
    background-color: #d8d8d9
.chapter_progresbar
    background-color: #4EA4B7
    border-radius: 4px
    height: 4px
    width: 80%
.chapter_progresbar_label
    font-size: 10px
    font-weight: 700

.chapter[keynavitemfocus]
    background-color: colors.$bg_13

@media screen and (min-width: 1281px)
    .chapter_title
        font-size: 20px
    .chapter_number
        font-size: 16px
    .chapter_date
        font-size: 12px
@keyframes fadein
    from
        opacity: 0
    to
        opacity: 1
