.wrap
  display: flex
  flex-direction: row
  cursor: pointer
  position: relative

  &:active
    transform: translateY(1px)

.main
  width: 270px
  height: 151px
  min-width: 270px
  min-height: 151px
  position: relative
  margin: 0 10px
  border-radius: 10px
  border: 5px solid transparent
  transition: all .2s

.picture
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-size: cover
  border-radius: 5px
  box-shadow: 0 0 20px #000
  overflow: hidden
  z-index: 2

.loading
  background-color: #000

// .wrap:hover,
[keynavitemfocus]
  .main
    border-color: #FFF
    transform: scale(1.05)

  .picture
    box-shadow: unset
