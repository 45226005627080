@use '../main/typo'

.main
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.5)
  display: flex
  justify-content: center
  align-items: center
  z-index: 99

.content
  width: 100%
  max-width: 500px
  background: #FFF
  color: #333
  border-radius: 13px
  position: relative
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25)
  padding: 20px 0

.header
  padding: 0 10px 0px
  text-align: center
  font-size: 21px
  font-weight: bold

.body
  padding: 0 10px
  font-size: 16px
  text-align: center

.btnClose
  width: 40px
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0
  right: 0
  cursor: pointer

  path
    fill: #000

  &:active
    transform: translateY(1px)

.footer
  padding-top: 20px
  flex-wrap: wrap
  justify-content: center
  flex-direction: row
  display: flex

  > *
    margin-right: 5px
    margin-left: 5px
