@use '../main/colors'

.bgImage
    padding-bottom: 70px
    background-position: top center

.ctn
    padding: 0
    // padding-left: 170px
    // padding-right: 40px

.content
    display: flex
    gap: 50px
    align-items: center
    padding-left: 170px

.flag
    background-color: colors.$bg_2
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100px
    height: 220px
    border-bottom-left-radius: 50px
    border-bottom-right-radius: 50px

    & > p
        font-size: 18px
        color: #fff
        max-width: 6ch
        text-align: center
        margin: 0
        & > span
            font-weight: 700

.title
    font-size: 45px
    font-weight: 700
    max-width: 20ch
    line-height: 1
    & > span
        color: #ff7637
// @media screen and (min-width: 1500px)
//     .bgImage
//         height: 600px
// @media screen and (min-width: 1600px)
//     .bgImage
//         height: 700px
