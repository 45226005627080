.main
  position: absolute
  top: 0
  left: 0
  width: 100%
  min-height: 100vh
  box-sizing: border-box

.inner
  padding: 30px 0

.title
  color: #FFF
  font-size: 50px
  font-weight: bold
  margin: 0px 0 30px 114px

.content
  overflow-x: hidden
  overflow-y: auto
  position: absolute
  top: 10vh
  left: 0
  padding-top: 40px
  height: 90vh
  box-sizing: border-box
  -webkit-mask-image: linear-gradient(0deg, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)

  &::-webkit-scrollbar
    display: none

.grid
  display: flex
  padding-top: 20px
  margin-left: 150px
  flex-direction: row
  flex-wrap: wrap

  > div
    margin-bottom: 20px
