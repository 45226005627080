@use '../main/colors'

.wrap
  background-color: #fff
  border-top-right-radius: 18px
  border-bottom-right-radius: 18px
  height: 30px
  width: 90px
  padding: 0 10px
  display: flex
  justify-content: center
  align-items: center
  text-transform: uppercase

.title
  color: #373342
  font-size: 14px
  font-weight: bold
