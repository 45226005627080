@use '../main/colors'

.main
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  box-sizing: border-box
  background-image: url('../../../public/images/unpermission-0.png')
  background-color: transparent
  background-size: cover
  display: flex
  align-items: center

.image
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-image: url('../../../public/images/unpermission-1.png')
  background-color: transparent
  background-size: cover

.inner
  position: relative
  z-index: 2
  padding: 0 130px

.title
  color: #FFF
  font-size: 30px
  width: 55%
  font-weight: bold
  margin: 30px 0 10px

.description
  margin: 0 0 70px
  font-size: 20px
  color: #FFF
  font-weight: bold

.strong
  color: colors.$bg_2

.buttons
  width: 50%
  text-align: center

  > div
    margin: 0 0 20px

.btnBack
  min-height: 40px
  font-size: 14px
  cursor: pointer

  &:active
    transform: translateY(1px)
