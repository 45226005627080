@use '../main/colors'

.main
    background-color: rgba(34, 34, 35,0.4)
    border: 4px solid transparent
    padding: 10px 20px
    cursor: pointer
    border-radius: 8px
    display: flex
    box-shadow: 0 0 2px #FF7637

.wrap[keynavitemfocus]
    .main
        border-color: #FF7637
        background-color: #222223

.channel_ctn
    padding-right: 15px
    border-right: 1px solid #FF7637
    display: flex
    align-items: flex-end
    width: 67px

.channel
    margin: 0
    color: #FF7637
    font-weight: 700
    font-size: 16px
    // font-size: 22px

.info
    display: flex
    align-items: center
    gap: 15px
    padding-left: 20px
.img
    width: 180px
    object-fit: cover
    object-position: top
.title
    font-size: 15px
    // font-size: 20px
    font-weight: 500
    color: #fff
    margin: 0

.chapter_number
    font-weight: 700
    font-size: 13px
    // font-size: 16px
    color: #999
    margin: 0
    width: 150px

.time_left
    margin: 0
    font-weight: 700
    margin-top: 10px
    font-size: 12px
    // font-size: 14px
    color: #F1F1F1
.progressBar_ctn
    margin-top: 10px
    border-radius: 4px
    height: 4px
    background-color: #D8D8D9
.progressBar
    background-color: colors.$bg_2
    height: 4px
