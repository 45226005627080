.main
  background-color: transparent
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100vh
  box-sizing: border-box
  overflow-x: hidden
  overflow-y: auto

.header
  padding-top: 50px
  display: flex
  justify-content: center

.content
  padding: 42px 0
  margin: 0 114px
  display: flex
  flex-wrap: wrap

  > div
    margin-bottom: 20px

.empty
  text-align: center
