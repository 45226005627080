$bg_1: #26212E
$fg_1: #F1F1F1

$bg_2: #FF5000

$bg_3: #635F68

$bg_4: #2E7787

$bg_5: #373342

$bg_6: #373342

$bg_7: #423D50

$bg_8: #9B6CFF

$bg_9: #7040D7

$bg_10: #26212E

$bg_11: #4A4A4A

$bg_12: #4EA4B7

$bg_13: #615a74