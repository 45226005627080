@use '../main/colors'

.main
  height: 40px
  width: 40px
  background-color: colors.$bg_5
  color: #FFF
  display: flex
  justify-content: center
  align-items: center
  border-radius: 100%
  cursor: pointer
  border-width: 3px 
  border-style: solid 
  border-color: transparent

  &[keynavitemfocus]
    background-color: colors.$bg_7
    border-color: #FFF

  &:active
    transform: translateY(1px)

.main_checked
  @extend .main
  background-color: #FFF

  &[keynavitemfocus]
    background-color: #EFEFEF
    border-color: colors.$bg_2


.icon
  width: 20px
  height: 20px
