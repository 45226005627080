.main
  display: block
  overflow: hidden

.label
  transform: translateX(185px)
  font-size: 18px
  font-weight: bold
  color: #FFF
  margin-top: 20px

.items
  display: flex
  width: 100vw
  overflow-x: auto
  padding-bottom: 20px
  padding-right: 20px
  padding-top: 10px
  box-sizing: border-box

  &::-webkit-scrollbar
    display: none

  > div:first-child
    margin-left: 170px
