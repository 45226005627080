.banner
  margin-left: 170px
  margin-right: 40px
  margin-top: 50px
  box-sizing: border-box
  overflow: hidden
  cursor: pointer
  position: relative

.banner_sliders
  overflow: hidden
  // height: 46vh
  height: 314px
  width: 100%
  position: relative

.banner_item_main
  height: 100%
  width: 100%
  background-repeat: no-repeat
  background-size: contain
  background-position: center
  opacity: 0
  transition: opacity 0.3s

.banner_item
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  box-sizing: border-box
  border-radius: 10px
  border: 5px solid transparent
  opacity: 1

  &:first-child
    opacity: 1
    .banner_item_main
      opacity: 1

  &[keynavitemfocus]
    opacity: 1
    border-color: #FFF

    .banner_item_main
      opacity: 1

  &[keynavitemlastfocus]
    opacity: 1

    .banner_item_main
      opacity: 1

.banner_dots
  position: absolute
  bottom: 40px
  right: 30px
  display: flex
  gap: 5px

.banner_dot
  border-radius: 100%
  background: #FFF
  width: 10px
  height: 10px
  opacity: 0.5
  transition: opacity 0.3s

.banner_dot_focus
  opacity: 1
