@use '../main/colors'

.main
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100vh
  box-sizing: border-box
  background-color: colors.$bg_5
  overflow-y: auto

.inner
  padding: 43px 0 0 180px
  box-sizing: border-box

.title
  color: #FFF
  font-size: 20px
  font-weight: normal
  margin: 0 0 10px 0

.tabs
  margin-top: 20px
  display: flex
  gap: 28px

.mainDate
  display: block
  color: #FFF
  font-size: 10px
  margin-top: 26px

.columns
  display: flex
  flex-direction: row
  width: 93%
  gap: 40px
  margin: 13px 0 0

.column
  width: 50%

.column_title
  background-color: colors.$bg_10
  margin: 0
  color: colors.$fg_1
  font-size: 10px
  display: flex
  height: 32px
  align-items: center
  padding: 0 13px
  gap: 9px
  font-weight: bold

  svg
    width: 16px

.footer
  font-size: 11px
  display: flex
  align-items: center
  gap: 5px

@media screen and (min-width: 1281px)
  .column_title
    font-size: 20px
    height: auto
    padding: 13px

    svg
      width: 40px

  .mainDate
    font-size: 20px

  .footer
    font-size: 20px