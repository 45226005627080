@use '../main/colors'

.main
  background-color: transparent
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100vh
  box-sizing: border-box
  overflow-x: hidden
  overflow-y: auto

.background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1

.backgroundShadow
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 2
  background: linear-gradient(180deg, rgba(colors.$bg_1,0) 0%, rgba(colors.$bg_1, 1) 100%), linear-gradient(90deg, rgba(colors.$bg_1,1) 0%, rgba(colors.$bg_1,1) 20%, rgba(colors.$bg_1,0) 100%)

.backgroundImage
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  background-color: transparent

.inner
  position: relative
  z-index: 2
  padding: 30px 150px

.logo_content
  margin-bottom: 20px
  width: 250px
  height: 211px

.logo
  transition: opacity .1s

.description
  color: #F1F1F1
  font-size: 18px
  width: 60%
  margin-bottom: 20px

.meta
  font-size: 15px
  font-weight: bold
  margin-bottom: 20px

  .separator
    padding: 10px
  
.options
  display: flex
  flex-direction: row
  gap: 20px
  margin-bottom: 50px

.tabs
  background-color: transparent

.tab_header
  border-bottom: 1px solid #F1F1F1
  padding: 10px 0
  margin-bottom: 20px

.tab_title
  font-weight: bold
  font-size: 18px

.tab_content
  display: flex
  flex-direction: row

.seasons
  min-width: 30%

.episodes
  min-width: 70%

  > div
    margin-bottom: 20px

.season
  display: flex
  align-items: center
  min-height: 80px
  margin-bottom: 5px
  cursor: pointer
  color: #999999
  padding-left: 20px

  &[keynavitemfocus]
    color: #AAA
    background-color: colors.$bg_7

  &:active
    transform: translateY(1px)

.season_selected
  @extend .season
  color: #FFF

  &[keynavitemfocus]
    background-color: colors.$bg_7
    color: #FFF

.season_title
  font-size: 18px
  font-weight: bold
  text-transform: uppercase
