@use '../main/colors'

.main
  min-width: 200px
  min-height: 45px
  background-color: colors.$bg_2
  border-radius: 78px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  border-width: 3px 
  border-style: solid 
  border-color: transparent

  &[keynavitemfocus]
    background-color: colors.$bg_2
    border-color: #FFF
    box-shadow: 0 2px 3px rgba(0,0,0, 0.3)

  &:active
    transform: translateY(1px)

.inner
  padding: 0 10px
  display: flex
  align-items: center

.icon
  margin-right: 10px

.label
  font-size: 14px
  color: #FFF
  font-weight: bold

.secundary
  background-color: #fafafa

  .label
    color: colors.$bg_1

  &[keynavitemfocus]
    background-color: #FFF
    border-color: colors.$bg_2
  
.simple
  background-color: transparent

  .label
    color: colors.$fg_1

  &[keynavitemfocus]
    background-color: transparent
    border-color: #FFF

.tiny
  min-width: 120px

.secundary-tiny
  @extend .secundary
  @extend .tiny

.disabled
  background-color: #666

  &[keynavitemfocus]
    background-color: #888

.big
  .label
    font-size: 18px

.tiny-principal
  min-width: 70px
  min-height: 20px

  .label
    font-size: 10px
    