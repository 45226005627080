.main
  position: absolute
  top: 0
  left: 0
  padding: 0 100px
  width: 100%
  height: 100%
  box-sizing: border-box
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box

.page
  border: none
  height: 700px
  min-width: 400px
  width: 100%

.logo
  svg
    display: block
    margin: 0 auto

.login_overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 10

.login
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  font-family: 'Open Sans', sans-serif

.head
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 50px

.title
  color: #FFF
  font-weight: bold
  font-size: 22px
  text-align: center

.tv_content
  display: flex
  justify-content: center
  padding-top: 50px
  align-items: center

.column_separator
  width: 1px
  height: 250px
  background-color: #f1f1f1

.column
  width: 41%

.column_inner
  padding: 0 40px

.column_title
  display: flex
  align-items: center
  justify-content: center
  margin: 0 0 20px

.column_title_icon
  display: inline-block
  margin: 0 10px 0 0

.column_title_label
  font-size: 16px
  font-weight: bold
  color: #FFF

.column_content
  color: #FFF
  font-size: 12px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

  p
    margin: 0 0 10px
    display: inline-block
    text-align: center

.column_code
  font-size: 22px
  font-weight: bold
  font-family: Verdana, Geneva, Tahoma, sans-serif

strong
  color: #ff5000

.image_qr
  width: 170px
  height: 170px
  background-color: #FFF
