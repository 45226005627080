.main
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 0 100px
  box-sizing: border-box
  display: flex
  justify-content: center
  align-items: center

.title
  color: #FFF
  font-size: 60px
  font-weight: bold
  margin: 50px 0 50px
  text-align: center

.content
  font-size: 20px
  margin-bottom: 30px
  text-align: center
  padding: 0 20px
  min-width: 400px

.label
  padding: 10px 0
  text-align: center
  font-size: 30px
  margin: 0
