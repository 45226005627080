@use '../main/colors'

.wrap
  display: flex
  flex-direction: row
  cursor: pointer
  // width: 293px
  width: 250px

  &:active
    transform: translateY(1px)

.main
  width: 250px
  margin: 0 auto
  position: relative
  border-radius: 10px
  transition: all .2s
  border: 5px solid transparent
  overflow: hidden

.inner
  background-color: colors.$bg_5
  height: 100%

.picture
  width: 100%
  height: 136px
  background-color: #000
  background-size: cover
  border-radius: 5px
  overflow: hidden
  box-shadow: 0 0 5px rgba(0,0,0,1)
  z-index: 2
  position: relative

.secundary
  // position: absolute
  // bottom: 0
  // left: 0
  width: 100%
  padding: 10px 10px
  background-color: colors.$bg_5
  font-size: 14px
  border-radius: 0 0 5px 5px
  box-sizing: border-box
  margin-bottom: -1px

.separator
  padding: 10px

.title
  color: #878787
  font-weight: bold
  margin: 0

[keynavitemfocus]
  .main
    border-color: #FFF
    transform: scale(1.05)

  .picture
    box-shadow: unset

.backgroundInfo
  .secundary
    display: none
.date
  margin: 0
  font-size: 16px
  font-weight: 700

  &[keynavitemfocus]
    .secundary
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(0,0,0,0.9)
      padding: 15px

      .date
        font-size: 20px
        display: block
        font-weight: bold
        margin: 0

      .separator
        display: none

      .title
        font-size: 14px
        color: #FFF
        font-weight: 300
        margin: 0

      .iconContent
        display: flex 
        position: absolute
        bottom: 15px
        left: 15px
        align-items: center

      .icon
        display: flex 
        align-items: center
        justify-content: center
        width: 40px
        height: 40px
        background-color: #FFF
        border-radius: 100%
        margin-right: 15px

        svg
          path
            fill: colors.$bg_2

      .iconLabel
        font-size: 16px


@media screen and (min-width: 1400px)
  .date
    font-size: 18px
